import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { Toast } from "primereact/toast";

const Notification = () => {
  const [messageDialog, setMessageDialog] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const toast = useRef(null);

  const selectedStaffTemplate = (option) => {
    if (option) {
      return (
        <div>
          <div>{option.email}</div>
        </div>
      );
    }
    return <span>Select a Staff</span>;
  };

  const staffOptionTemplate = (option) => {
    return (
      <div>
        <div>{option.position}</div>
        <div>{option.email}</div>
      </div>
    );
  };

  const hideDialog = () => {
    setMessageDialog(false);
  };

  const openNew = () => {
    setMessageDialog(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/api/v1/staffs")
      .then((response) => {
        let database = response.data.map((item) => {
          return {
            ...item,
            position: item.position.slice(100, -100),
          };
        });
        setStaffs(database);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendEmail = async () => {
    try {
      const response = await axios.post("/api/v1/send_email", {
        to_email: selectedStaff.email,
        subject: subject,
        message: message,
      });
      console.log(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail();
    hideDialog();
    console.log("Message sent successfully");
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Message sent successfully",
      life: 3000,
    });
  };

  const style = {
    float: "right",
  };

  return (
    <>
      {" "}
      <Toast ref={toast} />
      <div style={style}>
        <Button
          style={{ width: "215px", backgroundColor: "#00c869" }}
          icon="pi pi-plus"
          label="Notification"
          severity="success"
          onClick={openNew}
        />

        <Dialog
          visible={messageDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Please Send New Notification Message."
          modal
          className="p-fluid"
          onHide={hideDialog}
        >
          <form onSubmit={handleSubmit}>
            <div className="field form-group">
              <input
                name="subject"
                type="text"
                placeholder="Subject"
                required
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <div>
                <Dropdown
                  value={selectedStaff}
                  onChange={(e) => setSelectedStaff(e.value)}
                  options={staffs}
                  optionLabel="position"
                  placeholder="Select a Staff"
                  filter
                  filterBy="position"
                  filterPlaceholder="Search by position"
                  filterMatchMode="contains"
                  valueTemplate={selectedStaffTemplate}
                  itemTemplate={staffOptionTemplate}
                />
              </div>
              <textarea
                style={{ marginTop: "10px" }}
                name="message"
                placeholder="Write message..."
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group yesNo">
              <Button
                label="Cancel"
                icon="pi pi-times"
                onClick={hideDialog}
                className="decide"
              />
              <Button
                type="submit"
                label="Send Message"
                icon="pi pi-check"
                className="decide"
              />
            </div>
          </form>
        </Dialog>
      </div>
    </>
  );
};

export default Notification;

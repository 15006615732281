import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import Admin from "../components/Admin";
import Header from "../components/Header";

function AdminPage() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { isLoading, isError, message } = useSelector((state) => state.calls);

  useEffect(() => {
    if (isError) {
      toast(message);
    }
  }, [isError, message]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else if (user.permissions === 'user') {
      navigate("/");
    }
    console.log("user " + JSON.stringify(user));
  }, [user, navigate]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex">
      <div className="client">
        <Header />
        <div className="number">
          <Admin />
        </div>
      </div>
    </div>
  );
}

export default AdminPage;

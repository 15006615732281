import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function ImportCSV() {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const fileInputRef = useRef(null);
  const toast = useRef(null);
  const fileReader = new FileReader();
  const [setClients] = useState(null);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    const csvRows = string.split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = values.reduce((object, value, index) => {
        object[index] = value.replace(/"/g, ""); // Remove double quotes
        return object;
      }, {});
      return obj;
    });

    setArray(array);
  };

  const fileStyle = {
    width: "100%",
    color: "red",
    fontSize: "x-large",
    display: "none",
  };

  const Upload = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  }, [file]);

  const generateRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  const randomString = generateRandomString(100);

  const getDataImport = () => {
    axios
      .get("/api/v1/clients")
      .then((response) => {
        let database = response.data.map((item) => {
          return {
            ...item,
            first_name: item.first_name.slice(100, -100),
            last_name: item.last_name.slice(100, -100),
            company: item.company.slice(100, -100),
            position: item.position.slice(100, -100),
            address: item.address.slice(100, -100),
            phoneNumber: item.phoneNumber.slice(100, -100),
          };
        });
        setClients(database);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      for (let i = 1; i < array.length - 1; i++) {
        const tokenedGender = array[i][0];
        const tokenedFirst_name = randomString + array[i][1] + randomString;
        const tokenedLast_name = randomString + array[i][2] + randomString;
        const tokenedCompany = randomString + array[i][3] + randomString;
        const tokenedPosition = randomString + array[i][4] + randomString;
        const tokenedAddress = randomString + array[i][5] + randomString;
        const tokenedPhoneNumber = randomString + array[i][7] + randomString;
        const importData = {
          gender: tokenedGender,
          first_name: tokenedFirst_name,
          last_name: tokenedLast_name,
          company: tokenedCompany,
          position: tokenedPosition,
          address: tokenedAddress,
          email: array[i][6],
          phoneNumber: tokenedPhoneNumber,
        };
        const response = await axios.post("/api/v1/clients", importData);
        console.log(response.data);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Clients Added",
          life: 3000,
        });
        getDataImport();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ textAlign: "center" }} className="import">
      <Toast ref={toast} />
      <h3>Please Import Leads From File.</h3>
      <br />
      <form
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <input
          ref={fileInputRef}
          style={fileStyle}
          type={"file"}
          accept={".csv"}
          onChange={handleOnChange}
        />
        <button
          className="btn btn-block"
          onClick={Upload}
          startIcon={<CloudUploadIcon />}
        >
          Upload File
        </button>
        <h3 id="state"></h3>
        <button className="btn btn-block" onClick={onSubmit}>
          Save to Database
        </button>
      </form>

      <br />

      <table>
        <tbody>
          {array.map((item, index) => (
            <tr key={index}>
              {Object.values(item).map((val, index) => (
                <td key={index}>{val}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ImportCSV;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import Notification from "../components/Notification";
import GoogleCalender from "../components/GoogleCalender";
import Header from "../components/Header";
import { Button } from "primereact/button";

const Appointment = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { isLoading, isError, message } = useSelector((state) => state.calls);

  useEffect(() => {
    if (isError) {
      toast(message);
    }
  }, [isError, message]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  if (isLoading) {
    return <Spinner />;
  }
  const ViewGoogleCalendar = () => {
    window.open("https://calendar.google.com/calendar/u/0/r?tab=rc&pli=1");
  };

  return (
    <div className="flex">
      <div className="appointment-container">
        <Header />
        <div style={{ marginTop: "10px" }}>
          <Notification />
          <Button onClick={ViewGoogleCalendar}>View Google Calendar</Button>
        </div>
        <GoogleCalender />
      </div>
    </div>
  );
};

export default Appointment;

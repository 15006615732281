import React from "react";
import SidebarRight from "./SidebarRight";

const Header = () => {
  const path = window.location.pathname;
  const pathName = path.split("/")[1];

  return (
    <div className="p-toolbar p-component mb-4">
      <img
        src="logo-main.png"
        alt="logo"
        style={{ height: "50px", float: "left" }}
      ></img>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "fit-content",
        }}
      >
        <h1 className="blackP" style={{ marginRight: "25px" }}>
          {pathName}
        </h1>
        <SidebarRight />
      </div>
    </div>
  );
};

export default Header;

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import Header from './components/Header'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Register from './pages/Register'
import Clients from './pages/Client'
import Statistic from './pages/Statistic'
import Staff from './pages/Staff'
import Numbers from './pages/Number'
import CallManagement from './pages/CallManagement'
import Appointment from './pages/Appointment'
import FeedbackTrain from './pages/Feedback&Train'
import Admin from './pages/Admin'

function App() {
  return (
    <>
      <Router>
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/clients' element={<Clients />} />
            <Route path='/callManagement' element={<CallManagement />} />
            <Route path='/appointment' element={<Appointment />} />
            <Route path='/phoneNumbers' element={<Numbers />} />
            <Route path='/employees' element={<Staff />} />
            <Route path='/statistic' element={<Statistic />} />
            <Route path='/feedback' element={<FeedbackTrain />} />
            <Route path='/admin' element={<Admin />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
          </Routes>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App

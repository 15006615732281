import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const zoomOptions = {
  pan: {
    enabled: true,
    onPanStart({ chart, point }) {
      const area = chart.chartArea;
      const w25 = area.width * 0.25;
      const h25 = area.height * 0.25;
      if (
        point.x < area.left + w25 ||
        point.x > area.right - w25 ||
        point.y < area.top + h25 ||
        point.y > area.bottom - h25
      ) {
        return false; // abort
      }
    },
    mode: "xy",
  },
  zoom: {
    wheel: {
      enabled: true,
    },
    pinch: {
      enabled: true,
    },
    mode: "x",
  },
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    zoom: zoomOptions,
  },
};

const StatisticDetails = () => {
  const chartRef = useRef(null);

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Inbound Call",
        type: "line",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Outbound Call",
        type: "line",
        data: [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Abandoned Call",
        type: "line",
        data: [],
        borderColor: "rgb(238, 193, 55)",
        backgroundColor: "rgba(238, 193, 55, 0.5)",
      },
      {
        label: "New Appointment",
        type: "line",
        data: [],
        borderColor: "rgb(118, 219, 155)",
        backgroundColor: "rgba(118, 219, 155, 0.5)",
      },
    ],
  });

  const onResetZoom = () => {
    chartRef.current.resetZoom();
  };

  const onZoomPlus = () => {
    chartRef.current.zoom(1.1);
  };

  const onZoomMinus = () => {
    chartRef.current.zoom(0.9);
  };

  const getData = async () => {
    try {
      const response = await axios.get("/api/v1/statistics_all");

      const chartData = JSON.parse(JSON.stringify(data));
      chartData.datasets[0].data = [];
      chartData.datasets[1].data = [];
      chartData.datasets[2].data = [];
      chartData.datasets[3].data = [];

      response.data.forEach((rd) => {
        chartData.labels.push(rd.date);
        chartData.datasets[0].data.push(rd.inbound);
        chartData.datasets[1].data.push(rd.outbound);
        chartData.datasets[2].data.push(rd.abandoned);
        chartData.datasets[3].data.push(rd.appointment);
      });

      setData(chartData);
    } catch (err) {
      console.error("getData error: ", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div
        style={{
          backgroundColor: "#13344e",
          maxHeight: "700px",
          borderRadius: "10px",
          padding: "10px",
        }}
        className="center"
      >
        <Line ref={chartRef} options={options} data={data} />
      </div>

      <div className="center">
        <button className="marginRight btn btn-block" onClick={onResetZoom}>
          zoom reset
        </button>
        <button className="marginRight btn btn-block" onClick={onZoomPlus}>
          zoom +10%
        </button>
        <button className="marginRight btn btn-block" onClick={onZoomMinus}>
          zoom -10%
        </button>
      </div>
    </div>
  );
};

export default StatisticDetails;

import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import axios from "axios";

export default function TotalCallChart() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const getData = async () => {
    try {
      const response = await axios.get("/api/v1/data");
      const inbound = response.data.inbound;
      const outbound = response.data.outbound;
      const abandoned = response.data.abandoned;

      // Update chartData state with the data from the backend
      setChartData({
        labels: ["Inbound", "Outbound", "Abandoned"],
        datasets: [
          {
            data: [inbound, outbound, abandoned],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };
  const options = {
    cutout: "50%",
  };
  useEffect(() => {
    setChartOptions(options);
    getData();
  }, []);

  return (
    <div className="card flex justify-content-center">
      <Chart
        type="doughnut"
        data={chartData}
        options={chartOptions}
        className="w-full md:w-30rem"
        style={{ width: "400px", height: "400px" }}
      />
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "primereact/toast";

import Spinner from "../components/Spinner";
import TotalCallChart from "../components/TotalCallChart";
import axios from "axios";
import SidebarDashboard from "../components/Sidebar";

function Dashboard() {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    inboundData: "",
    outboundData: "",
    evarInboundData: "",
    evarOutboundData: "",
    abandonedData: "",
    rateAbandoned: "",
    evarTotal: "",
    rateTotal: "",
    newAppointmentData: "",
    newNotificationData: "",
    increaseRate: "",
  });

  const getData = () => {
    axios
      .get("/api/v1/data")
      .then((res) => {
        setData({
          inboundData: res.data.inbound,
          outboundData: res.data.outbound,
          evarInboundData: res.data.evarInbound,
          evarOutboundData: res.data.evarOutbound,
          abandonedData: res.data.abandoned,
          rateAbandoned: res.data.rateAbandoned,
          evarTotal: res.data.evarTotal,
          rateTotal: res.data.rateTotal,
          newAppointmentData: res.data.newAppointment,
          newNotificationData: res.data.newNotification,
          increaseRate: res.data.increaseRate,
        });
        if (data.newAppointmentData !== res.data.newAppointment) {
          toast.current.show({
            severity: "success",
            summary: "New Appointment",
            detail: "There is new appointment",
            life: 3000,
            sticky: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  });

  const convertSecondToTime = (secondsString) => {
    const seconds = parseInt(secondsString, 10);
    // const hours = Math.floor(seconds / (60 * 60));
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { isLoading, isError, message } = useSelector((state) => state.calls);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError, message]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex">
      <Toast ref={toast} />
      <div className="rest">
        <div className="grid-container">
          <div className="item1 box1">
            <p className="boxTitle">Call analysis</p>
            <div className="canvas">
              <TotalCallChart />
            </div>
          </div>
          <div className="item2 box1">
            <p className="boxTitle">Inbound calls today</p>
            <div className="smallGrid">
              <div className="smallItem">
                <h3 className="detail">{data.inboundData}</h3>
                <p>Total</p>
              </div>
              <div className="smallItem">
                <h3 className="detail">
                  {convertSecondToTime(data.evarInboundData)}
                  <span style={{ fontSize: "3vh" }}></span>
                </h3>
                <p>Avg.call time</p>
              </div>
            </div>
          </div>
          <div className="item3 box1">
            <p className="boxTitle">Outbound calls today</p>
            <div className="smallGrid">
              <div className="smallItem">
                <h3 className="detail">{data.outboundData}</h3>
                <p>Total</p>
              </div>
              <div className="smallItem">
                <h3 className="detail">
                  {convertSecondToTime(data.evarOutboundData)}
                  <span style={{ fontSize: "3vh" }}></span>
                </h3>
                <p>Avg.call time</p>
              </div>
            </div>
          </div>
          <div className="item7 box1">
            <p className="boxTitle">Call metrics</p>
            <div className="smallGrid">
              <div className="smallItem">
                <h3 className="detail">
                  {convertSecondToTime(data.evarTotal)}
                  <span style={{ fontSize: "3vh" }}></span>
                </h3>
                <p>Average call time</p>
                <p>{data.increaseRate}% vs yday</p>
              </div>
              <div className="smallItem">
                <h3 className="detail">
                  {data.rateTotal}
                  <span style={{ fontSize: "3vh" }}>%</span>
                </h3>
                <p>Calls resolved</p>
              </div>
            </div>
          </div>
          <div className="item6 box1">
            <div className="smallGrid">
              <div>
                <p className="boxTitle">New appointments today</p>
                <div className="smallItem">
                  <h3 className="detail">{data.newAppointmentData}</h3>
                  <p>Total</p>
                </div>
              </div>
              <div>
                <p className="boxTitle">New Notification today</p>
                <div className="smallItem">
                  <h3 className="detail">{data.newNotificationData}</h3>
                  <p>Total</p>
                </div>
              </div>
            </div>
          </div>
          <div className="item5 box2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="boxTitle">Abandoned today</p>
            </div>
            <div className="smallGrid">
              <div className="smallItem">
                <h3 className="detail">{data.abandonedData}</h3>
                <p>Total</p>
              </div>
              <div className="smallItem">
                <h3 className="detail">
                  {data.rateAbandoned}
                  <span style={{ fontSize: "3vh" }}>%</span>
                </h3>
                <p>Abandonment rate</p>
              </div>
            </div>
          </div>
          <div className="item4 box3">
            <SidebarDashboard />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import Feedback from "../components/Feedback";
import Header from "../components/Header";

const FeedbackTrain = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { isLoading, isError, message } = useSelector((state) => state.calls);

  useEffect(() => {
    if (isError) {
      toast(message);
    }
  }, [isError, message]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="flex">
      <div className="appointment-container">
        <Header />
        <Feedback />
      </div>
    </div>
  );
};

export default FeedbackTrain;

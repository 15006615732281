import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import axios from "axios";
import ImportModal from "./ImportModal";
import { useDispatch } from "react-redux";
import { createCall } from "../features/calls/callSlice";

export default function ClientsDemo() {
  const [clients, setClients] = useState(null);
  const [clientDialog, setClientDialog] = useState(false);
  const [deleteClientDialog, setDeleteClientDialog] = useState(false);
  const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
  const [callDialog, setCallDialog] = useState(false);
  const [selectedClients, setSelectedClients] = useState(null);
  const [numbers, setNumbers] = useState(null);
  const [callConfirmDialog, setCallConfirmDialog] = useState(false);
  const [caller, setCaller] = useState(null);
  const [callee, setCallee] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);
  const [buttonChange, setButtonChange] = useState(0); // New state variable
  const [searchInput, setSearchInput] = useState("");

  const [formData, setFormData] = useState({
    gender: "",
    first_name: "",
    last_name: "",
    company: "",
    position: "",
    address: "",
    email: "",
    phoneNumber: "",
  });

  const {
    gender,
    first_name,
    last_name,
    company,
    position,
    address,
    email,
    phoneNumber,
  } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const generateRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  const randomString = generateRandomString(100);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (buttonChange === 1) {
        // New client - send POST request
        const tokenedFirst_name =
          randomString + formData.first_name + randomString;
        const tokenedLast_name =
          randomString + formData.last_name + randomString;
        const tokenedCompany = randomString + formData.company + randomString;
        const tokenedPosition = randomString + formData.position + randomString;
        const tokenedAddress = randomString + formData.address + randomString;
        const tokenedPhoneNumber =
          randomString + formData.phoneNumber + randomString;
        const tokenedData = {
          ...formData,
          first_name: tokenedFirst_name,
          last_name: tokenedLast_name,
          company: tokenedCompany,
          position: tokenedPosition,
          address: tokenedAddress,
          phoneNumber: tokenedPhoneNumber,
        };
        const response = await axios.post("/api/v1/clients", tokenedData);
        console.log(response.data);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Clients Added",
          life: 3000,
        });
      } else if (buttonChange === 0) {
        // Edit operation - send PUT request
        const tokenedFirst_name =
          randomString + formData.first_name + randomString;
        const tokenedLast_name =
          randomString + formData.last_name + randomString;
        const tokenedCompany = randomString + formData.company + randomString;
        const tokenedPosition = randomString + formData.position + randomString;
        const tokenedAddress = randomString + formData.address + randomString;
        const tokenedPhoneNumber =
          randomString + formData.phoneNumber + randomString;
        const tokenedData = {
          ...formData,
          first_name: tokenedFirst_name,
          last_name: tokenedLast_name,
          company: tokenedCompany,
          position: tokenedPosition,
          address: tokenedAddress,
          phoneNumber: tokenedPhoneNumber,
        };
        const response = await axios.put(
          `/api/v1/clients/${formData.email}`,
          tokenedData
        );
        console.log(response.data);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Clients Edited",
          life: 3000,
        });
      }
    } catch (error) {
      console.log(error.response.data);
    }
    setFormData({
      gender: "",
      first_name: "",
      last_name: "",
      company: "",
      position: "",
      phoneNumber: "",
      address: "",
      email: "",
    });
    setClientDialog(false);
    setButtonChange(0);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/api/v1/clients")
      .then((response) => {
        let database = response.data.map((item) => {
          return {
            ...item,
            first_name: item.first_name.slice(100, -100),
            last_name: item.last_name.slice(100, -100),
            company: item.company.slice(100, -100),
            position: item.position.slice(100, -100),
            address: item.address.slice(100, -100),
            phoneNumber: item.phoneNumber.slice(100, -100),
          };
        });
        setClients(database);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Fetch data from the backend API
    axios
      .get("/api/v1/numbers")
      .then((response) => {
        setNumbers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getNumberData = () => {
    axios
      .get("/api/v1/numbers")
      .then((response) => {
        setNumbers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const call = (phoneNumber) => {
    setCallConfirmDialog(true);
    setCaller(phoneNumber);
  };

  const dispatch = useDispatch();
  const callNow = (e) => {
    e.preventDefault();
    dispatch(createCall({ caller, callee }));
    setCallConfirmDialog(false);
    setCallDialog(false);
  };

  const openNew = () => {
    setSubmitted(false);
    setFormData({
      gender: "",
      first_name: "",
      last_name: "",
      company: "",
      position: "",
      phoneNumber: "",
      address: "",
      email: "",
    });
    setClientDialog(true);
    setButtonChange(1);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setClientDialog(false);
  };

  const hideDeleteClientDialog = () => {
    setDeleteClientDialog(false);
  };

  const hideDeleteClientsDialog = () => {
    setDeleteClientsDialog(false);
  };

  const hideCallConfirmDialog = () => {
    setCallConfirmDialog(false);
  };

  const openCallDialog = (client) => {
    setCallDialog(true);
    setCallee(client.phoneNumber);
    console.log("Collee " + JSON.stringify(client));
    console.log("Collee " + client.phoneNumber);
    console.log("Collee " + callee);
    getNumberData();
  };

  const hideCallDialog = () => {
    setCallDialog(false);
  };

  const editClient = (client) => {
    setSubmitted(false);
    setFormData(client);
    setClientDialog(true);
    setButtonChange(0);
  };

  const confirmDeleteClient = (client) => {
    setDeleteClientDialog(true);
    setFormData(client);
  };

  const confirmDeleteSelected = () => {
    setDeleteClientsDialog(true);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const deleteSelectedClients = async () => {
    try {
      // Delete the selected clients from the database
      await Promise.all(
        selectedClients.map(async (client) => {
          await axios.delete(`/api/v1/clients/${client.email}`);
        })
      );
      // Remove the deleted clients from the state
      const updatedClients = clients.filter(
        (client) => !selectedClients.includes(client)
      );
      setClients(updatedClients);
      setDeleteClientsDialog(false);
      setSelectedClients(null);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Clients Deleted",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };
  const deleteClient = async () => {
    try {
      // Delete the selected client from the database
      await axios.delete(`/api/v1/clients/${formData.email}`);
      // Remove the deleted client from the state
      const updatedClients = clients.filter(
        (client) => client.email !== formData.email
      );
      setClients(updatedClients);
      hideDeleteClientDialog(); // Hide the delete client dialog
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Client Deleted",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <ImportModal />
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedClients || !selectedClients.length}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "Space-around" }}>
          <Button
            icon="pi pi-pencil"
            rounded
            outlined
            severity="success"
            className="mr-2"
            onClick={() => editClient(rowData)}
          />
          <Button
            icon="pi pi-trash"
            rounded
            outlined
            className="mr-2"
            severity="danger"
            onClick={() => confirmDeleteClient(rowData)}
          />
          <Button
            icon="pi pi-phone"
            rounded
            outlined
            severity="info"
            onClick={() => openCallDialog(rowData)}
          />
        </div>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Clients</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => {
            setSearchInput(e.target.value);
            if (e.target.value.length >= 2) {
              setGlobalFilter(e.target.value);
            }
          }}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const callDialogHeader = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => {
            setSearchInput(e.target.value);
            if (e.target.value.length >= 2) {
              setGlobalFilter(e.target.value);
            }
          }}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const deleteClientDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteClientDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteClient}
      />
    </React.Fragment>
  );

  const callConfirmDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideCallConfirmDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={callNow}
      />
    </React.Fragment>
  );

  const deleteClientsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteClientsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedClients}
      />
    </React.Fragment>
  );

  const onGenderChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      gender: e.value,
    }));
  };
  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>
        <DataTable
          ref={dt}
          value={clients}
          selection={selectedClients}
          onSelectionChange={(e) => setSelectedClients(e.value)}
          dataKey="email"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} clients"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="gender"
            header="Gender"
            sortable
            style={{
              minWidth: "8rem",
              maxWidth: "8rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="first_name"
            header="First name"
            sortable
            style={{
              minWidth: "8rem",
              maxWidth: "8rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="last_name"
            header="Last name"
            sortable
            style={{
              minWidth: "8rem",
              maxWidth: "8rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="company"
            header="Company"
            sortable
            style={{
              minWidth: "10rem",
              maxWidth: "10rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="position"
            header="Position"
            sortable
            style={{
              minWidth: "12rem",
              maxWidth: "12rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="address"
            header="Address"
            sortable
            style={{
              minWidth: "12rem",
              maxWidth: "12rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="email"
            header="Email"
            sortable
            style={{
              minWidth: "12rem",
              maxWidth: "12rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="phoneNumber"
            header="Phone number"
            sortable
            style={{
              minWidth: "10rem",
              maxWidth: "10rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "13rem", maxWidth: "13rem" }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={clientDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Client's Details"
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <form onSubmit={onSubmit}>
          <div className="field form-group">
            <label className="mb-3 font-bold">Gender</label>
            <div
              className="formgrid grid"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div
                className="field-radiobutton col-6"
                style={{ display: "flex" }}
              >
                <RadioButton
                  inputId="gender1"
                  name="gender"
                  value="Mr"
                  onChange={onGenderChange}
                  checked={gender === "Mr"}
                />
                <label htmlFor="gender1">Mr</label>
              </div>
              <div
                className="field-radiobutton col-6"
                style={{ display: "flex" }}
              >
                <RadioButton
                  inputId="gender2"
                  name="gender"
                  value="Ms"
                  onChange={onGenderChange}
                  checked={gender === "Ms"}
                />
                <label htmlFor="gender2">Ms</label>
              </div>
            </div>
          </div>
          <div className="field form-group">
            <label htmlFor="first_name" className="font-bold">
              First name
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={first_name}
              onChange={onChange}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !first_name,
              })}
            />
            {submitted && !first_name && (
              <small className="p-error">First name is required.</small>
            )}
          </div>
          <div className="field form-group">
            <label htmlFor="name" className="font-bold">
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={last_name}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && last_name,
              })}
            />
            {submitted && !last_name && (
              <small className="p-error">Last name is required.</small>
            )}
          </div>
          <div className="field form-group">
            <label htmlFor="name" className="font-bold">
              Company
            </label>
            <input
              type="text"
              id="company"
              name="company"
              value={company}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && company,
              })}
            />
            {submitted && !company && (
              <small className="p-error">Company is required.</small>
            )}
          </div>
          <div className="field form-group">
            <label htmlFor="name" className="font-bold">
              Position
            </label>
            <input
              type="text"
              id="position"
              name="position"
              value={position}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && !position,
              })}
            />
            {submitted && !position && (
              <small className="p-error">Position is required.</small>
            )}
          </div>
          <div className="field form-group">
            <label htmlFor="name" className="font-bold">
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={address}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && !address,
              })}
            />
            {submitted && !address && (
              <small className="p-error">Address is required.</small>
            )}
          </div>
          <div className="field form-group">
            <label htmlFor="name" className="font-bold">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && !email,
              })}
            />
            {submitted && !email && (
              <small className="p-error">Email is required.</small>
            )}
          </div>
          <div className="field form-group">
            <label htmlFor="name" className="font-bold">
              Phone number
            </label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && !phoneNumber,
              })}
            />
            {submitted && !phoneNumber && (
              <small className="p-error">Phone number is required.</small>
            )}
          </div>
          <div className="form-group yesNo">
            <Button
              label="Cancel"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
              className="decide"
            />
            <Button
              type="submit"
              label="Save"
              icon="pi pi-check"
              className="decide"
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        visible={deleteClientDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteClientDialogFooter}
        onHide={hideDeleteClientDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              Are you sure you want to delete{" "}
              <b>
                {gender} {first_name}
              </b>
              ?
            </span>
          }
        </div>
      </Dialog>
      <Dialog
        visible={callConfirmDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={callConfirmDialogFooter}
        onHide={hideCallConfirmDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              Are you sure you want to use this Phone number <b>{caller}</b>?
            </span>
          }
        </div>
      </Dialog>
      <Dialog
        visible={deleteClientsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteClientsDialogFooter}
        onHide={hideDeleteClientsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>Are you sure you want to delete the selected clients?</span>}
        </div>
      </Dialog>
      <Dialog
        visible={callDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Please Select Phone Number."
        modal
        className="p-fluid"
        onHide={hideCallDialog}
      >
        <DataTable
          ref={dt}
          value={numbers}
          selection={call}
          dataKey="phoneNumber"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} phone numbers"
          globalFilter={globalFilter}
          header={callDialogHeader}
          selectionMode="single"
          onRowSelect={(value) => {
            call(value.data.phoneNumber);
          }}
        >
          <Column
            field="phoneNumber"
            header="Phone number"
            sortable
            style={{ minWidth: "20rem" }}
          ></Column>
        </DataTable>
      </Dialog>
    </div>
  );
}

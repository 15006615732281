import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import axios from "axios";

export default function NumberList() {
  const [numbers, setNumbers] = useState(null);
  const [numberDialog, setNumberDialog] = useState(false);
  const [deleteNumberDialog, setDeleteNumberDialog] = useState(false);
  const [deleteNumbersDialog, setDeleteNumbersDialog] = useState(false);
  const [selectedNumbers, setSelectedNumbers] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);
  const [buttonChange, setButtonChange] = useState(0); // New state variable
  const [searchInput, setSearchInput] = useState("");

  const [formData, setFormData] = useState({
    phoneNumber: "",
  });

  const { phoneNumber } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (buttonChange === 1) {
        // New number - send POST request
        const response = await axios.post("/api/v1/numbers", formData);
        console.log(response.data);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Phone Number Added",
          life: 3000,
        });
      } else if (buttonChange === 0) {
        // Edit operation - send PUT request
        const response = await axios.put(
          `/api/v1/number/${formData.id}`,
          formData
        );
        console.log(response.data);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Numbers Edited",
          life: 3000,
        });
      }
    } catch (error) {
      console.log(error.response.data);
    }

    // Clear form data and reset buttonChange after submission
    setFormData({
      phoneNumber: "",
    });
    setNumberDialog(false);
    setButtonChange(0);
    getData();
  };

  useEffect(() => {
    // Fetch data from the backend API
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/api/v1/numbers")
      .then((response) => {
        setNumbers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openNew = () => {
    setSubmitted(false);
    setFormData({
      phoneNumber: "",
    });
    setNumberDialog(true);
    setButtonChange(1);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setNumberDialog(false);
  };

  const hideDeleteNumberDialog = () => {
    setDeleteNumberDialog(false);
  };

  const hideDeleteNumbersDialog = () => {
    setDeleteNumbersDialog(false);
  };

  const confirmDeleteNumber = (number) => {
    setDeleteNumberDialog(true);
    setFormData(number);
  };

  const confirmDeleteSelected = () => {
    setDeleteNumbersDialog(true);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const deleteSelectedNumbers = async () => {
    try {
      // Delete the selected numbers from the database
      await Promise.all(
        selectedNumbers.map(async (number) => {
          await axios.delete(`/api/v1/numbers/${number.phoneNumber}`);
        })
      );

      // Remove the deleted numbers from the state
      const updatedNumbers = numbers.filter(
        (number) => !selectedNumbers.includes(number)
      );
      setNumbers(updatedNumbers);

      setDeleteNumbersDialog(false);
      setSelectedNumbers(null);

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Phone Numbers Deleted",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const deleteNumber = async () => {
    try {
      // Delete the selected number from the database
      await axios.delete(`/api/v1/numbers/${formData.phoneNumber}`);

      // Remove the deleted number from the state
      const updatedNumbers = numbers.filter(
        (number) => number.phoneNumber !== formData.phoneNumber
      );
      setNumbers(updatedNumbers);

      hideDeleteNumberDialog(); // Hide the delete number dialog

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Phone Number Deleted",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedNumbers || !selectedNumbers.length}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "Space-around" }}>
          <Button
            icon="pi pi-trash"
            rounded
            outlined
            className="mr-2"
            severity="danger"
            onClick={() => confirmDeleteNumber(rowData)}
          />
        </div>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Phone Number</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => {
            setSearchInput(e.target.value);
            if (e.target.value.length >= 2) {
              setGlobalFilter(e.target.value);
            }
          }}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const deleteNumberDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteNumberDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteNumber}
      />
    </React.Fragment>
  );
  const deleteNumbersDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteNumbersDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedNumbers}
      />
    </React.Fragment>
  );

  return (
    <div style={{ width: "50%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          ref={dt}
          value={numbers}
          selection={selectedNumbers}
          onSelectionChange={(e) => setSelectedNumbers(e.value)}
          dataKey="phoneNumber"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} phone numbers"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="phoneNumber"
            header="Phone number"
            sortable
            style={{ minWidth: "20rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "10rem" }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={numberDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Please Add New Phone Number."
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <form onSubmit={onSubmit}>
          <div className="field form-group">
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && !phoneNumber,
              })}
            />
            {submitted && !phoneNumber && (
              <small className="p-error">Phone number is required.</small>
            )}
          </div>
          <div className="form-group yesNo">
            <Button
              label="Cancel"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
              className="decide"
            />
            <Button
              type="submit"
              label="Save"
              icon="pi pi-check"
              className="decide"
            />
          </div>
        </form>
      </Dialog>

      <Dialog
        visible={deleteNumberDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteNumberDialogFooter}
        onHide={hideDeleteNumberDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              Are you sure you want to delete <b>{phoneNumber}</b>?
            </span>
          }
        </div>
      </Dialog>

      <Dialog
        visible={deleteNumbersDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteNumbersDialogFooter}
        onHide={hideDeleteNumbersDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              Are you sure you want to delete the selected phone numbers?
            </span>
          }
        </div>
      </Dialog>
    </div>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../features/auth/authSlice";
import "primeicons/primeicons.css";

export default function SidebarDashboard() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };

  return (
    <div>
      <div
        className="flex align-items-center gap-2"
        style={{ justifyContent: "space-between" }}
      >
        <div class="flex align-items-center gap-2">
          <img src="logo-main.png" alt="logo" style={{ width: "50px" }}></img>
          <span
            className="font-bold sideLogo"
            style={{ fontSize: "2rem", cursor: "pointer" }}
          >
            TULP.AI
          </span>
          <span className="version">V0.8</span>
        </div>
      </div>
      <ul className="sideUl">
        <li className="sideLi">
          <i className="pi pi-home" style={{ fontSize: "1.3rem" }}></i>
          <a href="/" className="iconGap">
            Dashboard
          </a>
        </li>
        <li className="sideLi">
          <i className="pi pi-users" style={{ fontSize: "1.3rem" }}></i>
          <a href="/clients" className="iconGap">
            Client
          </a>
        </li>
        <li className="sideLi">
          <i className="pi pi-user" style={{ fontSize: "1.3rem" }}></i>
          <a href="/employees" className="iconGap">
            Employee
          </a>
        </li>
        <li className="sideLi">
          <i className="pi pi-phone" style={{ fontSize: "1.3rem" }}></i>
          <a href="/phoneNumbers" className="iconGap">
            Phone Number
          </a>
        </li>
        <li className="sideLi">
          <i className="pi pi-whatsapp" style={{ fontSize: "1.3rem" }}></i>
          <a href="/callManagement" className="iconGap">
            Call Management
          </a>
        </li>
        <li className="sideLi">
          <i className="pi pi-calendar-plus" style={{ fontSize: "1.3rem" }}></i>
          <a href="/appointment" className="iconGap">
            Appointment
          </a>
        </li>
        <li className="sideLi">
          <i className="pi pi-chart-line" style={{ fontSize: "1.3rem" }}></i>
          <a href="/statistic" className="iconGap">
            Statistic
          </a>
        </li>
        <li className="sideLi">
          <i className="pi pi-pencil" style={{ fontSize: "1.3rem" }}></i>
          <a href="/feedback" className="iconGap">
            Feedback & Training
          </a>
        </li>
        { user && user.permissions === 'admin' && (
        <li className="sideLi">
          <i className="pi pi-user-plus" style={{ fontSize: "1.3rem" }}></i>
          <a href="/admin" className="iconGap">
            Admin
          </a>
        </li>)
        }
        <li className="sideLi">
          <i className="pi pi-sign-out" style={{ fontSize: "1.3rem" }}></i>
          <a onClick={onLogout} className="iconGap">
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from "axios";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const GoogleCalendar = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const getData = () => {
    axios
      .get("/api/v1/appointment")
      .then((response) => {
        console.log(response.data);
        setEvents(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={{ height: 700, marginTop: "10px" }}>
      <Calendar
        events={events}
        step={60}
        defaultDate={new Date()}
        localizer={localizer}
        popup={true}
        selectable={true} // Enable selection of time range
        onSelectEvent={handleSelectEvent}
      />
      {selectedEvent && (
        <div>
          <h2>{selectedEvent.title}</h2>
          <p>Start Time: {moment(selectedEvent.start).format("LLL")}</p>
          <p>End Time: {moment(selectedEvent.end).format("LLL")}</p>
          <p>{selectedEvent.desc}</p>
        </div>
      )}
    </div>
  );
};

export default GoogleCalendar;
import { useState, useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import axios from "axios";

const Feedback = () => {
  const toast = useRef(null);
  const [formData, setFormData] = useState({
    transcription: "",
    call_id: "",
  });

  const { transcription } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (event) => {
    try {
      const response = await axios.post("/api/v1/feedback", formData);
      console.log(response.data);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Feedback Sent",
        life: 3000,
      });
      setFormData({ transcription: "" });
    } catch (error) {
      setFormData({ transcription: "" });
      console.log(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(event);
  };
  const [count, setCount] = useState("");

  const getCount = async () => {
    try {
      const response = await axios.get("/api/v1/feedback/count");
      setCount(response.data.count);
      console.log(response.data.count);
    } catch (error) {
      console.error("Error Training count:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getCount();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const Train = async() => {
    await axios
      .post("/api/v1/train")
      .then((response) => {
        console.log(count);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Training Started. Please wait until the training is finished.",
          life: 3000,
        });
      })
      .catch((error) => {
        console.log(error.response.data.detail);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.detail,
          life: 3000,
        });
      });
  };

const TrainStyle = {
    display: count > 9 && count !== undefined ? 'block' : 'none'
};

  return (
    <div>
      <Toast ref={toast} />
      <form onSubmit={handleSubmit}>
        <div className="field form-group">
          <textarea
            name="transcription"
            placeholder="Write feedback..."
            required
            value={transcription}
            onChange={onChange}
          ></textarea>
        </div>
        <div className="form-group yesNo">
          <Button
            type="submit"
            label="Send Feedback"
            icon="pi pi-check"
            className="decide"
          />
        </div>
      </form>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          label={`Train ${count}`}
          icon="pi pi-check"
          className="decide"
          style={TrainStyle}
          onClick={Train}
        />
      </div>
    </div>
  );
};

export default Feedback;

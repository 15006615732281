import { useState } from "react";
import { useDispatch } from "react-redux";
import { createCall } from "../features/calls/callSlice";

function CallForm() {
  const [callee, setCallee] = useState("");
  const [showWarning, setShowWarning] = useState(null);
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    if (callee === "") {
      setShowWarning(true); // Display warning message
    } else {
      dispatch(createCall({ callee }));
      setCallee("");
      setShowWarning(false);
    }
  };

  return (
    <section className="form">
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="text">Phone Number</label>
          <input
            type="text"
            name="callee"
            id="callee"
            value={callee}
            required
            onChange={(e) => {
              const sanitizedValue = e.target.value.replace(/[^+\d]/g, ""); // Remove non-digit characters except "+"
              setCallee(sanitizedValue);
              setShowWarning(false);
            }}
            placeholder={
              showWarning ? "Please enter phone number (+16562186682)" : ""
            }
          />
        </div>
        <div className="form-group">
          <button className="btn btn-block" type="submit">
            Call
          </button>
        </div>
      </form>
    </section>
  );
}

export default CallForm;

import axios from 'axios'

const API_URL = '/api/v1/'

// Register user
const register = async (userData) => {
  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('name', userData.name);
  formData.append('username', userData.email);
  formData.append('password', userData.password);
  formData.append('password2', userData.password2);

  const response = await axios.post(API_URL + 'register', formData)

  // if (response.data) {
  //   localStorage.setItem('user', JSON.stringify(response.data))
  // }

  return response.data
}

// Login user
const login = async (userData) => {
  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('username', userData.email);
  formData.append('password', userData.password);

  const response = await axios.post(API_URL + 'login', formData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }

  return response.data
}

// Logout user
const logout = () => {
  localStorage.removeItem('user')
}

const authService = {
  register,
  logout,
  login,
}

export default authService

import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from 'primereact/checkbox';
        
import axios from "axios";

export default function Admin() {
  const [users, setUsers] = useState(null);
  const [userDialog, setUserDialog] = useState(false);
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [deleteUsersDialog, setDeleteUsersDialog] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");

  const toast = useRef(null);
  const dt = useRef(null);
  const [buttonChange, setButtonChange] = useState(0); // New state variable
  const [searchInput, setSearchInput] = useState("");

  const [formData, setFormData] = useState({
    email: "",
  });

  const { user } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (buttonChange === 1) {
        // New user - send POST request
        const response = await axios.post("/api/v1/users", formData);
        console.log(response.data);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "User Added",
          life: 3000,
        });
      } else if (buttonChange === 0) {
        // Edit operation - send PUT request
        const response = await axios.put(
          `/api/v1/user/${formData.id}`,
          formData
        );
        console.log(response.data);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "User Edited",
          life: 3000,
        });
      }
    } catch (error) {
      console.log(error.response.data);
    }

    // Clear form data and reset buttonChange after submission
    setFormData({
      email: "",
    });
    setUserDialog(false);
    setButtonChange(0);
    getData();
  };

  useEffect(() => {
    // Fetch data from the backend API
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/api/v1/users")
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const hideDialog = () => {
    setSubmitted(false);
    setUserDialog(false);
  };

  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };

  const hideDeleteUsersDialog = () => {
    setDeleteUsersDialog(false);
  };

  const confirmDeleteUser = (user) => {
    setDeleteUserDialog(true);
    setFormData(user);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const deleteSelectedUsers = async () => {
    try {
      // Delete the selected users from the database
      await Promise.all(
        selectedUsers.map(async (user) => {
          await axios.delete(`/api/v1/users/${user.email}`);
        })
      );

      // Remove the deleted users from the state
      const updatedUsers = users.filter(
        (user) => !selectedUsers.includes(user)
      );
      setUsers(updatedUsers);

      setDeleteUsersDialog(false);
      setSelectedUsers(null);

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "User Deleted",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const deleteUser = async () => {
    try {
      // Delete the selected user from the database
      await axios.delete(`/api/v1/users/${formData.email}`);

      // Remove the deleted user from the state
      const updatedUsers = users.filter(
        (user) => user.email !== formData.email
      );
      setUsers(updatedUsers);

      hideDeleteUserDialog(); // Hide the delete user dialog

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "User Deleted",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "Space-around" }}>
          <Button
            icon="pi pi-trash"
            rounded
            outlined
            className="mr-2"
            severity="danger"
            onClick={() => confirmDeleteUser(rowData)}
          />
        </div>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage User</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => {
            setSearchInput(e.target.value);
            if (e.target.value.length >= 2) {
              setGlobalFilter(e.target.value);
            }
          }}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const deleteUserDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteUserDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteUser}
      />
    </React.Fragment>
  );
  const deleteUsersDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteUsersDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedUsers}
      />
    </React.Fragment>
  );

  return (
    <div style={{ width: "50%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          right={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          ref={dt}
          value={users}
          selection={selectedUsers}
          onSelectionChange={(e) => setSelectedUsers(e.value)}
          dataKey="user"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
          globalFilter={globalFilter}
          header={header}
        >
          {/* <Column selectionMode="multiple" exportable={false}></Column> */}
          <Column
            field="email"
            header="User"
            sortable
            style={{ minWidth: "20rem" }}
          ></Column>
          <Column
            body={AllowTemplate}
            exportable={false}
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "10rem" }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={userDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Please Add New User."
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <form onSubmit={onSubmit}>
          <div className="field form-group">
            <input
              type="email"
              id="user"
              name="user"
              value={user}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && !user,
              })}
            />
            {submitted && !user && (
              <small className="p-error">User is required.</small>
            )}
          </div>
          <div className="form-group yesNo">
            <Button
              label="Cancel"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
              className="decide"
            />
            <Button
              type="submit"
              label="Save"
              icon="pi pi-check"
              className="decide"
            />
          </div>
        </form>
      </Dialog>

      <Dialog
        visible={deleteUserDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteUserDialogFooter}
        onHide={hideDeleteUserDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              Are you sure you want to delete <b>{user}</b>?
            </span>
          }
        </div>
      </Dialog>

      <Dialog
        visible={deleteUsersDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteUsersDialogFooter}
        onHide={hideDeleteUsersDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>Are you sure you want to delete the selected users?</span>}
        </div>
      </Dialog>
    </div>
  );
}


const AllowTemplate = (rowData) => {
  const [active, setActive] = useState(rowData.is_active);
    
  const handleUserActive = async (userId)  => {
    const response = await axios.put(
      `/api/v1/users/${userId}`,
    );
    setActive(() => !active);
    // toast.current.show({
    //   severity: "success",
    //   summary: "Successful",
    //   detail: "User status changed",
    //   life: 3000,
    // });
  }

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "Space-around" }}>
        <Checkbox
          onChange={(e) => handleUserActive(rowData.id)}
          checked={active}
        ></Checkbox>
      </div>
    </React.Fragment>
  );
};
import axios from 'axios'

const API_URL = '/api/v1/calls'

// Create new call
const createCall = async (callData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post('/api/v1/outbound', callData, config)
  return response.data
}

// Delete user call
const deleteCall = async (callId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + callId, config)

  return response.data
}

const callService = {
  createCall,
  deleteCall,
}

export default callService

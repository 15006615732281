import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import StatisticDetails from "../components/Statistic";
import Header from "../components/Header";

function Statistic() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { isLoading, isError, message } = useSelector((state) => state.calls);

  useEffect(() => {
    if (isError) {
      toast(message);
    }
  }, [isError, message]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex">
      <div className="client">
        <Header />
        <StatisticDetails />
      </div>
    </div>
  );
}

export default Statistic;

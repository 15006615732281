import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login, reset } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error("Authentication Failed.");
    }

    if (isSuccess) {
      navigate("/");
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email,
      password,
    };
    dispatch(login(userData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="w3layouts-main">
      <div className="bg-layer">
        <div className="header-main">
          <div className="main-icon">
            <img
              src="logo-tulp-dark.png"
              alt="logo"
              style={{ width: "70px", height: "90px" }}
            />
          </div>
          <div className="header-left-bottom">
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={email}
                  placeholder="Enter your email"
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={password}
                  placeholder="Enter password"
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-block login">
                  Login
                </button>
              </div>

              <div className="links">
                {/* <p className="left">
                  <a href="/login/reset">Forgot<br/> Password?</a>
                </p> */}
                <p className="right">
                  <a
                    href="/register"
                    style={{ fontSize: "large", marginRight: "10px" }}
                  >
                    New User? Register
                  </a>
                </p>
                <div className="clear"></div>
              </div>
            </form>
          </div>
        </div>
        <div className="copyright">
          <p>
            {/* Copyright © 2024 Built by{" "} */}
            <a href="/#" target="_blank">
              Copyright © 2024 OneStone Ai Solutions, All rights reserved.
            </a>
          </p>
        </div>
        <div
          className="onestone"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <img
            src="images/onestone.png"
            alt="onestone"
            style={{ width: "50%", height: "50%", marginRight: "10px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;

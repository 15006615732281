import * as React from "react";
import Box from "@mui/material/Box";
import { Button } from "primereact/button";
import Modal from "@mui/material/Modal";
import ImportCSV from "./ImportCSV";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  backgroundColor: "background.paper",
  borderRadius: "10px",
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  padding: "20px",
};

const ImportModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        label="Import"
        icon="pi pi-plus"
        severity="info"
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ImportCSV/>
        </Box>
      </Modal>
    </div>
  );
};

export default ImportModal;

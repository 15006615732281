import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import axios from "axios";
// import { createCall } from "../features/calls/callSlice";
import { useDispatch } from "react-redux";

export default function StaffsDemo() {
  const [staffs, setStaffs] = useState(null);
  const [staffDialog, setStaffDialog] = useState(false);
  const [deleteStaffDialog, setDeleteStaffDialog] = useState(false);
  const [deleteStaffsDialog, setDeleteStaffsDialog] = useState(false);
  // const [callDialog, setCallDialog] = useState(false);
  const [notificationDialog, setNotificationDialog] = useState(false);
  const [numbers, setNumbers] = useState(null);
  // const [callConfirmDialog, setCallConfirmDialog] = useState(false);
  // const [callee, setCallee] = useState(null);
  const [selectedStaffs, setSelectedStaffs] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);
  const [buttonChange, setButtonChange] = useState(0); // New state variable
  const [searchInput, setSearchInput] = useState("");

  //---------------------
  const [formData, setFormData] = useState({
    gender: "",
    first_name: "",
    last_name: "",
    position: "",
    address: "",
    email: "",
    phoneNumber: "",
  });

  const {
    gender,
    first_name,
    last_name,
    position,
    address,
    email,
    phoneNumber,
  } = formData;

  const [toEmail, setToEmail] = useState("");

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  //=================================================
  const generateRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  const randomString = generateRandomString(100);
  //====================================================

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (buttonChange === 1) {
        // New staff - send POST request
        const tokenedFirst_name =
          randomString + formData.first_name + randomString;
        const tokenedLast_name =
          randomString + formData.last_name + randomString;
        const tokenedPosition = randomString + formData.position + randomString;
        const tokenedAddress = randomString + formData.address + randomString;
        const tokenedPhoneNumber =
          randomString + formData.phoneNumber + randomString;
        const tokenedData = {
          ...formData,
          first_name: tokenedFirst_name,
          last_name: tokenedLast_name,
          position: tokenedPosition,
          address: tokenedAddress,
          phoneNumber: tokenedPhoneNumber,
        };
        const response = await axios.post("/api/v1/staffs", tokenedData);
        console.log(response.data);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Staffs Added",
          life: 3000,
        });
      } else if (buttonChange === 0) {
        // Edit operation - send PUT request
        const tokenedFirst_name =
          randomString + formData.first_name + randomString;
        const tokenedLast_name =
          randomString + formData.last_name + randomString;
        const tokenedPosition = randomString + formData.position + randomString;
        const tokenedAddress = randomString + formData.address + randomString;
        const tokenedPhoneNumber =
          randomString + formData.phoneNumber + randomString;
        const tokenedData = {
          ...formData,
          first_name: tokenedFirst_name,
          last_name: tokenedLast_name,
          position: tokenedPosition,
          address: tokenedAddress,
          phoneNumber: tokenedPhoneNumber,
        };
        const response = await axios.put(
          `/api/v1/staffs/${formData.email}`,
          tokenedData
        );
        console.log(response.data);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Staffs Edited",
          life: 3000,
        });
      }
    } catch (error) {
      console.log(error.response.data);
    }

    // Clear form data and reset buttonChange after submission
    setFormData({
      gender: "",
      first_name: "",
      last_name: "",
      position: "",
      phoneNumber: "",
      address: "",
      email: "",
    });
    setStaffDialog(false);
    setButtonChange(0);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/api/v1/staffs")
      .then((response) => {
        let database = response.data.map((item) => {
          return {
            ...item,
            first_name: item.first_name.slice(100, -100),
            last_name: item.last_name.slice(100, -100),
            position: item.position.slice(100, -100),
            address: item.address.slice(100, -100),
            phoneNumber: item.phoneNumber.slice(100, -100),
          };
        });
        setStaffs(database);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    // Fetch data from the backend API
    axios
      .get("/api/v1/numbers")
      .then((response) => {
        setNumbers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getNumberData = () => {
    axios
      .get("/api/v1/numbers")
      .then((response) => {
        setNumbers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const call = (phoneNumber) => {
  //   setCallConfirmDialog(true);
  //   setCallee(phoneNumber);
  // };

  const dispatch = useDispatch();

  // const callNow = (e) => {
  //   e.preventDefault();
  //   dispatch(createCall({}));
  //   console.log(callee);
  //   setCallConfirmDialog(false);
  // };

  const openNew = () => {
    setSubmitted(false);
    setFormData({
      gender: "",
      first_name: "",
      last_name: "",
      position: "",
      phoneNumber: "",
      address: "",
      email: "",
    });
    setStaffDialog(true);
    setButtonChange(1);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setStaffDialog(false);
  };

  const hideDeleteStaffDialog = () => {
    setDeleteStaffDialog(false);
  };

  const hideDeleteStaffsDialog = () => {
    setDeleteStaffsDialog(false);
  };

  // const hideCallConfirmDialog = () => {
  //   setCallConfirmDialog(false);
  // };

  // const openCallDialog = () => {
  //   setCallDialog(true);
  //   getNumberData();
  // };

  const openNotificationDialog = (staff) => () => {
    setNotificationDialog(true);
    setToEmail(staff.email);
  };

  // const hideCallDialog = () => {
  //   setCallDialog(false);
  // };

  const hideNotificationDialog = () => {
    setNotificationDialog(false);
    setToEmail({
      email: "",
    });
  };

  const editStaff = (staff) => {
    setSubmitted(false);
    setFormData(staff);
    setStaffDialog(true);
    setButtonChange(0);
  };

  const confirmDeleteStaff = (staff) => {
    setDeleteStaffDialog(true);
    setFormData(staff);
  };

  const confirmDeleteSelected = () => {
    setDeleteStaffsDialog(true);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const deleteSelectedStaffs = async () => {
    try {
      // Delete the selected staffs from the database
      await Promise.all(
        selectedStaffs.map(async (staff) => {
          await axios.delete(`/api/v1/staffs/${staff.email}`);
        })
      );

      // Remove the deleted staffs from the state
      const updatedStaffs = staffs.filter(
        (staff) => !selectedStaffs.includes(staff)
      );
      setStaffs(updatedStaffs);
      setDeleteStaffsDialog(false);
      setSelectedStaffs(null);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Staffs Deleted",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const deleteStaff = async () => {
    try {
      // Delete the selected staff from the database
      await axios.delete(`/api/v1/staffs/${formData.email}`);

      // Remove the deleted staff from the state
      const updatedStaffs = staffs.filter(
        (staff) => staff.email !== formData.email
      );
      setStaffs(updatedStaffs);
      hideDeleteStaffDialog(); // Hide the delete staff dialog
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Staff Deleted",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedStaffs || !selectedStaffs.length}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "Space-around" }}>
          <Button
            icon="pi pi-pencil"
            rounded
            outlined
            severity="success"
            className="mr-2"
            onClick={() => editStaff(rowData)}
          />
          <Button
            icon="pi pi-trash"
            rounded
            outlined
            className="mr-2"
            severity="danger"
            onClick={() => confirmDeleteStaff(rowData)}
          />
          {/* <Button
            icon="pi pi-phone"
            rounded
            outlined
            className="mr-2"
            severity="info"
            onClick={openCallDialog}
          /> */}
          <Button
            icon="pi pi-comment"
            rounded
            outlined
            className="mr-2"
            severity="warn"
            onClick={openNotificationDialog(rowData)}
          />
        </div>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Staffs</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => {
            setSearchInput(e.target.value);
            if (e.target.value.length >= 2) {
              setGlobalFilter(e.target.value);
            }
          }}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const callDialogHeader = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => {
            setSearchInput(e.target.value);
            if (e.target.value.length >= 2) {
              setGlobalFilter(e.target.value);
            }
          }}
          placeholder="Search..."
        />
      </span>
    </div>
  );
  const deleteStaffDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteStaffDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteStaff}
      />
    </React.Fragment>
  );

  // const callConfirmDialogFooter = (
  //   <React.Fragment>
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       outlined
  //       onClick={hideCallConfirmDialog}
  //     />
  //     <Button
  //       label="Yes"
  //       icon="pi pi-check"
  //       severity="danger"
  //       onClick={callNow}
  //     />
  //   </React.Fragment>
  // );
  const deleteStaffsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteStaffsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedStaffs}
      />
    </React.Fragment>
  );

  const onGenderChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      gender: e.value,
    }));
  };

  const [subject, setSubject] = useState("");

  const [message, setMessage] = useState("");

  const sendEmail = async () => {
    try {
      const response = await axios.post("/api/v1/send_email", {
        to_email: toEmail,
        subject: subject,
        message: message,
      });

      console.log(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail();
    console.log("Message sent successfully");
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Message sent successfully",
      life: 3000,
    });
    setNotificationDialog(false);
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          ref={dt}
          value={staffs}
          selection={selectedStaffs}
          onSelectionChange={(e) => setSelectedStaffs(e.value)}
          dataKey="email"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} staffs"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="gender"
            header="Gender"
            sortable
            style={{
              minWidth: "8rem",
              maxWidth: "8rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="first_name"
            header="First name"
            sortable
            style={{
              minWidth: "10rem",
              maxWidth: "12rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="last_name"
            header="Last name"
            sortable
            style={{
              minWidth: "10rem",
              maxWidth: "12rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="position"
            header="Position"
            sortable
            style={{
              minWidth: "12rem",
              maxWidth: "14rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="address"
            header="Address"
            sortable
            style={{
              minWidth: "12rem",
              maxWidth: "14rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="email"
            header="Email"
            sortable
            style={{
              minWidth: "12rem",
              maxWidth: "14rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="phoneNumber"
            header="Phone number"
            sortable
            style={{
              minWidth: "10rem",
              maxWidth: "12rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "13rem", maxWidth: "12rem" }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={staffDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Staff's Details"
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <form onSubmit={onSubmit}>
          <div className="field form-group">
            <label className="mb-3 font-bold">Gender</label>
            <div
              className="formgrid grid"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div
                className="field-radiobutton col-6"
                style={{ display: "flex" }}
              >
                <RadioButton
                  inputId="gender1"
                  name="gender"
                  value="Mr"
                  onChange={onGenderChange}
                  checked={gender === "Mr"}
                />
                <label htmlFor="gender1">Mr</label>
              </div>
              <div
                className="field-radiobutton col-6"
                style={{ display: "flex" }}
              >
                <RadioButton
                  inputId="gender2"
                  name="gender"
                  value="Ms"
                  onChange={onGenderChange}
                  checked={gender === "Ms"}
                />
                <label htmlFor="gender2">Ms</label>
              </div>
            </div>
          </div>
          <div className="field form-group">
            <label htmlFor="first_name" className="font-bold">
              First name
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={first_name}
              onChange={onChange}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !first_name,
              })}
            />
            {submitted && !first_name && (
              <small className="p-error">First name is required.</small>
            )}
          </div>
          <div className="field form-group">
            <label htmlFor="name" className="font-bold">
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={last_name}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && last_name,
              })}
            />
            {submitted && !last_name && (
              <small className="p-error">Last name is required.</small>
            )}
          </div>
          <div className="field form-group">
            <label htmlFor="name" className="font-bold">
              Position
            </label>
            <input
              type="text"
              id="position"
              name="position"
              value={position}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && !position,
              })}
            />
            {submitted && !position && (
              <small className="p-error">Position is required.</small>
            )}
          </div>
          <div className="field form-group">
            <label htmlFor="name" className="font-bold">
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={address}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && !address,
              })}
            />
            {submitted && !address && (
              <small className="p-error">Address is required.</small>
            )}
          </div>
          <div className="field form-group">
            <label htmlFor="name" className="font-bold">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && !email,
              })}
            />
            {submitted && !email && (
              <small className="p-error">Email is required.</small>
            )}
          </div>
          <div className="field form-group">
            <label htmlFor="name" className="font-bold">
              Phone number
            </label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              onChange={onChange}
              required
              className={classNames({
                "p-invalid": submitted && !phoneNumber,
              })}
            />
            {submitted && !phoneNumber && (
              <small className="p-error">Phone number is required.</small>
            )}
          </div>
          <div className="form-group yesNo">
            <Button
              label="Cancel"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
              className="decide"
            />
            <Button
              type="submit"
              label="Save"
              icon="pi pi-check"
              className="decide"
            />
          </div>
        </form>
      </Dialog>

      <Dialog
        visible={deleteStaffDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteStaffDialogFooter}
        onHide={hideDeleteStaffDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              Are you sure you want to delete{" "}
              <b>
                {gender} {first_name}
              </b>
              ?
            </span>
          }
        </div>
      </Dialog>
      {/* <Dialog
        visible={callConfirmDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={callConfirmDialogFooter}
        onHide={hideCallConfirmDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              Are you sure you want to use this Phone number <b>{callee}</b>?
            </span>
          }
        </div>
      </Dialog> */}
      <Dialog
        visible={deleteStaffsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteStaffsDialogFooter}
        onHide={hideDeleteStaffsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>Are you sure you want to delete the selected staffs?</span>}
        </div>
      </Dialog>
      {/* <Dialog
        visible={callDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Please Select Phone Number."
        modal
        className="p-fluid"
        onHide={hideCallDialog}
      >
        <DataTable
          ref={dt}
          value={numbers}
          selection={call}
          dataKey="phoneNumber"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} phone numbers"
          globalFilter={globalFilter}
          header={callDialogHeader}
          selectionMode="single"
          onRowSelect={(value) => {
            call(value.data.phoneNumber);
          }}
        >
          <Column
            field="phoneNumber"
            header="Phone number"
            sortable
            style={{ minWidth: "20rem" }}
          ></Column>
        </DataTable>
      </Dialog> */}
      <Dialog
        visible={notificationDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Please Send Notification To This Employee."
        modal
        className="p-fluid"
        onHide={hideNotificationDialog}
      >
        <form onSubmit={handleSubmit}>
          <div className="field form-group">
            <input
              name="subject"
              type="subject"
              placeholder="Subject"
              required
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <input
              type="email"
              id="email"
              name="email"
              value={toEmail}
              readOnly
              required
            />
            <textarea
              name="message"
              placeholder="Write message..."
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="form-group yesNo">
            <Button
              label="Cancel"
              icon="pi pi-times"
              outlined
              onClick={hideNotificationDialog}
              className="decide"
            />
            <Button
              type="submit"
              label="Send Message"
              icon="pi pi-check"
              className="decide"
            />
          </div>
        </form>
      </Dialog>
    </div>
  );
}
